import Logo from "../../assets/images/header/Header-logo.png";
import styles from "./header.module.scss"

const Header = (props) => {

	return (
		<div className={styles.BottomLine} onClick={()=> {
			window.location.replace("/");
		}}>
			<img src={Logo} className={styles.HeaderLogoIcon} />
			<h1>
				Invoice
			</h1>
		</div>
	);
}

export default Header;
