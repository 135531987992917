import React from "react";

function CardIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.75 20.5625C1.75 21.3747 2.07266 22.1537 2.64699 22.728C3.22132 23.3023 4.00027 23.625 4.8125 23.625H23.1875C23.9997 23.625 24.7787 23.3023 25.353 22.728C25.9273 22.1537 26.25 21.3747 26.25 20.5625V12.1406H1.75V20.5625ZM5.35938 16.4062C5.35938 15.9711 5.53223 15.5538 5.8399 15.2462C6.14758 14.9385 6.56488 14.7656 7 14.7656H9.625C10.0601 14.7656 10.4774 14.9385 10.7851 15.2462C11.0928 15.5538 11.2656 15.9711 11.2656 16.4062V17.5C11.2656 17.9351 11.0928 18.3524 10.7851 18.6601C10.4774 18.9678 10.0601 19.1406 9.625 19.1406H7C6.56488 19.1406 6.14758 18.9678 5.8399 18.6601C5.53223 18.3524 5.35938 17.9351 5.35938 17.5V16.4062ZM23.1875 4.375H4.8125C4.00027 4.375 3.22132 4.69766 2.64699 5.27199C2.07266 5.84632 1.75 6.62527 1.75 7.4375V8.85938H26.25V7.4375C26.25 6.62527 25.9273 5.84632 25.353 5.27199C24.7787 4.69766 23.9997 4.375 23.1875 4.375Z" />
    </svg>
  );
}

export default CardIcon;
