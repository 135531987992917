import React from 'react';
import styles from './homepage.module.scss';
import Apple from '../../assets/images/AppStore-eng 1.png';
import Android from '../../assets/images/GooglePlay-eng 1.png';
import Link from '../../assets/images/mdi_external-link.png';
import { translateMessage } from '../../helper/constant';

function Homepage() {
  return (
    <div className={styles.MainSection}>
      <div className={`${styles.MidSection} row`}>
        <div className='col-md-6'>
          <h1>
            GlobiancePay <span>Invoice</span>
          </h1>
          <h5>
            {translateMessage("Pay your invoices with popular crypto currencies by using")} <span>{translateMessage("ANY")}</span> {translateMessage("Wallet")} !
          </h5>
          <button onClick={()=> window.open(process.env.REACT_APP_MERCHANT_INVOICE, "_blank")}>
            <p>{translateMessage("CREATE INVOICE")}</p>
            <img src={Link} alt='mdi_external-link' />
          </button>
        </div>
        <div className='col-md-6 align-items-center'>
          <div className={styles.rightBanner}>
          </div>
        </div>
      </div>
      <div className={styles.Bottom}>
        <div>
          <a href={process.env.REACT_APP_APPLE_LINK} target="_blank">
            <img src={Apple} alt='android-image' />
          </a>
          <a href={process.env.REACT_APP_ANDROID_LINK} target="_blank">
            <img src={Android} alt='android-image' />
          </a>
        </div>
        <div>
          <p>
            {translateMessage("Download the Globiance App Now")}!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Homepage