import { useDispatch, useSelector } from 'react-redux'
import * as action from '../../store/actions/index';
import { translateMessage } from '../../helper/constant';


const BackBar = ({ title }: { title?: string }) => {
    const dispatch = useDispatch();
    const modalState = useSelector((state: ReduxState) => state.widgetReducer);

    return (
        <div className={`go-back-bar ${title ? 'between' : 'end'}`}>
            {title && <h6>{title}</h6>}
            <p onClick={() => dispatch(action.updateWidgetScreenState(modalState.back))} className='cursor text-end w-100'>{"< "}{translateMessage("Go back")}</p>
        </div>
    )
}

export default BackBar