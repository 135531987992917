import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-circular-progressbar/dist/styles.css';
import Copy from '../../assets/images/Copy';
import 'react-tabs/style/react-tabs.css';
import { copyClipboard, translateMessage } from '../../helper/constant';

const PaymentDetailScreen = ({ paymentDetail }: { paymentDetail: IPaymentDetail }) => {

    const date = (dat: string) => new Date(dat).toLocaleString()

    const renderTransactionDetail = () => {
        if (paymentDetail.transactionType === "blockchain") {
            const transactionDetail = paymentDetail.transactionDetail;

            return <div className='trans-det'>
                <div className='detail-sec'>
                    <div className='row-li'>
                        <p>{translateMessage("Address")}</p>
                        <div className='address'>
                            <p>{transactionDetail.address}</p>
                            <span className='cursor' onClick={() => copyClipboard(transactionDetail.address)}><Copy /></span>
                        </div>
                    </div>
                    <div className='row-li'>
                        <p>{translateMessage("Transaction Hash")}</p>
                        <div className='address'>
                            <p>{transactionDetail.txHash}</p>
                            <span className='cursor' onClick={() => copyClipboard(transactionDetail.txHash)}><Copy /></span>
                        </div>
                    </div>
                    <div className='row-li'>
                        <p>{translateMessage("Status")}</p>
                        <p className='status'>{transactionDetail.status}</p>
                    </div>
                    <div className='row-li'>
                        <p>{translateMessage("Amount")}</p>
                        <p>{transactionDetail.amount}</p>
                    </div>
                    {
                        transactionDetail.network ?
                            <div className='row-li'>
                                <p>{translateMessage("Network")}</p>
                                <p>{transactionDetail.network}</p>
                            </div>
                            : <></>
                    }
                    <div className='row-li'>
                        <p>{translateMessage("Confirmations")}</p>
                        <p>{transactionDetail.confirmations} / {transactionDetail.confirmationRequired}</p>
                    </div>
                </div>
                <h4>{translateMessage("Confirmations")}</h4>
                <div className="bar">
                    {[...Array(transactionDetail.confirmationRequired)].map((_, index) => {
                        if (index + 1 === Math.ceil(transactionDetail.confirmations / 2)) {
                            return <div className="bar-block done" style={{ width: 100 / transactionDetail.confirmationRequired + '%' }}>
                                <p>{transactionDetail.confirmations} / {transactionDetail.confirmationRequired}</p>
                            </div>
                        }
                        if (index + 1 <= transactionDetail.confirmations) {
                            return <div className="bar-block done" style={{ width: 100 / transactionDetail.confirmationRequired + '%' }}></div>
                        }
                        if (index + 1 > transactionDetail.confirmations) {
                            return <div className="bar-block remaining" style={{ width: 100 / transactionDetail.confirmationRequired + '%' }}></div>
                        }
                    }
                    )}
                </div>
            </div>
        } else {
            const transactionDetail = paymentDetail.transactionDetail;
            return <div className='trans-det'>
                <div className='detail-sec'>

                    <div className='row-li'>
                        <p>{translateMessage("From User")}</p>
                        <p className='status'>{transactionDetail.payerEmail}</p>
                    </div>
                    <div className='row-li no-bd'>
                        <p>{translateMessage("Amount")}</p>
                        <p>{transactionDetail.amount}</p>
                    </div>
                </div>
            </div>
        }
    }

    const renderPaymentStatusHistory = () => {
        const status = getProgress();

        return <div className='payment-status-sec'>
            <ul className="StepProgress">
                <li className={`StepProgress-item ${status.processing ? "is-done" : ""}`} ><h6>{translateMessage("Processing")}</h6><p>{status.processing && date(status.processing.createdAt)}</p></li>
                {status.dispute ? <>
                    <li className={`StepProgress-item is-done`} ><h6>{translateMessage("Disputed")}</h6><p>{date(status.dispute.createdAt!)}</p></li>
                </> :
                    <>
                        <li className={`StepProgress-item ${status.confirmed ? "is-done" : ""}`} ><h6>{translateMessage("Confirmed")}</h6><p>{status.confirmed && date(status.confirmed.createdAt!)}</p></li>
                        <li className={`StepProgress-item ${status.settled ? "is-done" : ""}`} ><h6>{translateMessage("Settled")}</h6><p>{status.settled && date(status.settled.createdAt!)}</p></li>
                    </>
                }
            </ul>
        </div>

    }

    type GetStatus = {
        "processing": "warning",
        "confirmed": "success",
        "refunded": "primary",
        "disputed": "danger",
        "partially refunded": "info",
    }

    const getStatus: GetStatus = {
        "processing": "warning",
        "confirmed": "success",
        "refunded": "primary",
        "disputed": "danger",
        "partially refunded": "info",
    }

    // overrite
    const getProgress = (): { processing: IPaymentHistory | undefined, confirmed: IPaymentHistory | undefined, settled: IPaymentHistory | undefined, dispute: IPaymentHistory | undefined } => {
        let processing, confirmed, settled, dispute;

        for (let item of paymentDetail.paymenStatusHistory) {
            if (item.action === "processing") {
                processing = item;
            } else if (item.action === "confirmed") {
                confirmed = item
            } else if (item.action === "settled") {
                settled = item
            }
            else if (item.action === "dispute") {
                dispute = item
            }
        }
        return { processing, confirmed, settled, dispute }
    }


    const DetailsComponent = <div className='detail-sec'>
        <div className='row-li'>
            <p>{translateMessage("Payment ID")}</p>
            <div className='address'>
                <p>{paymentDetail.paymentId}</p>
                <span className='cursor' onClick={() => copyClipboard(paymentDetail.paymentId)}><Copy /></span>
            </div>
        </div>
        <div className='row-li'>
            <p>{translateMessage("Status")}</p>
            <p className='status'>{paymentDetail.status}</p>
        </div>
        <div className='row-li'>
            <p>{translateMessage("Currency Name")}</p>
            <p>{paymentDetail.currencyName}</p>
        </div>
        <div className='row-li'>
            <p>{translateMessage("Amount")}</p>
            <p>{paymentDetail.amount}</p>
        </div>
        {paymentDetail.isUnderDispute && <>
            <div className='row-li'>
                <p>{translateMessage("Is under dispute")}</p>
                <p>{paymentDetail.isUnderDispute ? 'True' : 'False'}</p>
            </div>
            <div className='row-li'>
                <p>{translateMessage("Dispute Type")}</p>
                <p>{paymentDetail.disputeType}</p>
            </div>
        </>}
        <div className='row-li no-bd'>
            <p>{translateMessage("Transaction Type")}</p>
            <p>{paymentDetail.transactionType}</p>
        </div>
    </div>

    const blockchainTransactionDetail = renderTransactionDetail()
    const paymenStatusHistory = renderPaymentStatusHistory()

    return (
        <>
            <Tabs >
                <TabList className="tab-navigation-group tab-responsive">
                    <Tab className="tab-navigation-group-item">{translateMessage("Details")}</Tab>
                    <Tab className="tab-navigation-group-item">{translateMessage("Transaction Detail")}</Tab>
                    <Tab className="tab-navigation-group-item">{translateMessage("Payment Status History")}</Tab>
                </TabList>

                <TabPanel>
                    {DetailsComponent}
                </TabPanel>
                <TabPanel>
                    {blockchainTransactionDetail}
                </TabPanel>
                <TabPanel>
                    {paymenStatusHistory}
                </TabPanel>
            </Tabs>
        </>
    );

}

export default React.memo(PaymentDetailScreen)