import { useEffect, useState } from 'react';
import { PadTo2 } from '../../helper/constant';
import * as action from "../../store/actions";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const Timer = ({
  ms,
  panicTime = 60000,
}: {
  ms: number,
  panicTime?: number
}) => {
  const [timeLeft, setTimeLeft] = useState(ms);
  const dispatch = useDispatch();
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const interval = 1000;

  let intRef: any;

  useEffect(() => {
    intRef = setTimeout(() => {
      const nextTimeLeft = timeLeft - interval;
      if (nextTimeLeft < 0) {
        setTimeLeft(0);
        clearTimeout(intRef);
        if (!modalState.expired) dispatch(action.updateWidgetScreenState('Expired'));
        dispatch({ type: 'EXPIRED' });
        localStorage.removeItem('checkoutID')
        return;
      }

      setTimeLeft(nextTimeLeft);
    }, interval);

    return () => {
      clearTimeout(intRef);
    };
  }, [timeLeft, interval, dispatch, modalState.expired]);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  const displayTime = () => {
    if (days > 0) {
      if(days === 1){
        return `${PadTo2(days)} Day ${PadTo2(hours)}:${PadTo2(minutes)}:${PadTo2(seconds)}`;
      }else{
        return `${PadTo2(days)} Days ${PadTo2(hours)}:${PadTo2(minutes)}:${PadTo2(seconds)}`;
      }
    } else if (hours > 0) {
      return `${PadTo2(hours)}:${PadTo2(minutes)}:${PadTo2(seconds)}`;
    } else {
      return `${PadTo2(minutes)}:${PadTo2(seconds)}`;
    }
  };

  return (
    <span>
      {displayTime()}
    </span>
  );
};
