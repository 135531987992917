import BackBar from "./BackBar";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import * as action from "../../store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import PaySection from "../../assets/images/PaySection";
import {
  dispatchNotifications,
  GetLogosrc,
  Paybis_origin,
  translateMessage,
} from "../../helper/constant";
import { Apple } from "../../assets/images/Apple";
import { Android } from "../../assets/images/Android";

function InitializePaybis() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);
  const [external, setExternal] = useState(false);

  function isValidEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function getType() {
    const exist = modalState.checkoutDetails.quotes
      .filter((item) => "BTC" === item.currencyName)
      .find((item) => "blockchain" === item.currencyType);
    if (exist) return exist;
    return null;
  }

  const paybisWithBTC = async (body: any) => {
    let data = {
      body: body
    }

    var dataString = JSON.stringify(data);
    var dataStringBase64 = window.btoa(dataString);
    var dataStringBase64Safe = encodeURIComponent(dataStringBase64);

    var url = Paybis_origin + dataStringBase64Safe;
    window.open(url, '_blank');
  }

  const initializePaybisWidget = async () => {
    setLoading(true);
    let quote = getType();

    if (quote) {
      let data = {
        currencyId: quote.currencyId,
        apiKey: modalState.widgetData.apikey,
        checkoutId: modalState.checkoutData.checkoutId,
      };
      dispatch(action.getBlockchainWalletAddress(data, async (address: any) => {
        let body = {
          email,
          cryptoWalletAddress: {
            "address": address,
            "currencyCode": quote!.currencyName
          },
          amount: quote!.subTotal,
          fromCurrency: modalState.checkoutDetails.requestCurrency,
          toCurrency: "BTC"
        }
        await paybisWithBTC(body);
        setLoading(false);
      }, () => {
        setLoading(false);
      }));
    } else {
      dispatchNotifications({
        message: `BTC is not enabled by user`,
        color: 'red'
      });
      setLoading(false);
    }
  };

  let currencyList: string[] = ["btc", "eth", "bnb", "xdc", "xrp"];

  return (
    <>
      <BackBar />
      <div className="initializePaybis-screen">
        {external ? <div className="top-section">
          <div className="buy-crypto-button no-cursor mb-1">
            <h6>{translateMessage("Buy Crypto on an external website")}</h6>
            <p>{translateMessage("by card no registration needed")}</p>
          </div>
          <div className="buy-crypto">
            <h6>{translateMessage("Buy Crypto")}</h6>
            <div className="logo-list">
              {currencyList.map((item) => (
                <img src={GetLogosrc(item)} alt="Currency Logo" />
              ))}
              <span>& {translateMessage("more")}</span>
            </div>
          </div>
          <div className="visa-section">
            <p>
              {translateMessage("This checkout is supporting Crypto Payments only. If you don't have crypto yet, we can forward you to Globiance.com Crypto Exchange where you can purchase Crypto with Credit and Debit Cards, Bank Transfer and other Payment methods.")}
              <br />
              {translateMessage("Press continue to be forwarded to")} Globiance.com
            </p>
            <span>
              <p>{translateMessage("We accept")}: </p>
              <PaySection />
            </span>
          </div>


          <div className="mt-2 email-sec">
            <input
              type="text"
              placeholder={translateMessage("enter your email address")}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!modalState.checkoutDetails.enableFIATBuy}
            />

            <div>
              <button
                disabled={(!email || !isValidEmail()) || loading || !modalState.checkoutDetails.enableFIATBuy}
                onClick={() => initializePaybisWidget()}
              >
                {!modalState.checkoutDetails.enableFIATBuy ? translateMessage("Enable on Request") : translateMessage("CONTINUE")}{" "}
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <Spinner className="invisible" animation="border" />
                )}
              </button>
            </div>

          </div>

          <h6 className="or-separator">{translateMessage("OR")}</h6>

          <div className="open-glob-wallet" onClick={() => setExternal(false)}>
            <span>{translateMessage("Recommended")}</span>
            <h5>{translateMessage("Open a")} GlobiancePay Wallet</h5>
            <p>
              {translateMessage("Enjoy the lowest fees and you will receive a")}{" "}
              <span>{translateMessage("FREE virtual Debit card!")}</span>
            </p>
          </div>
        </div> : <div className="top-section">
          <div className="open-glob-wallet no-cursor">
            <span>{translateMessage("Recommended")}</span>
            <h5>{translateMessage("Open a")} GlobiancePay Wallet</h5>
            <p>
              {translateMessage("Enjoy the lowest fees and you will receive a")}{" "}
              <span>{translateMessage("FREE virtual Debit card!")}</span>
            </p>
          </div>

          <div className="visa-section">
            <p>
              {translateMessage("Top Up the wallet with Credit Card and pay by App. The wallet is available worldwide for everyone age 18+ without any restrictions and can be topped up with credit/debit card, 700 Alternate Payment Methods, 70+ Fiat Currencies or Crypto Currencies. Order a GlobiancePay Card for your wallet in 5 minutes.")} <br />
              <b>{translateMessage("Get a FREE Virtual Credit Card with every wallet.")}</b><br />
              {translateMessage("Sign up on web")} <a href="https://wallet.globiancepay.com" target="_blank" rel="noopener noreferrer">https://wallet.globiancepay.com</a>
              <br />{translateMessage("or Download App:")}
              <a
                href={process.env.REACT_APP_APPLE_LINK}
                target="_blank"
              >
                <Apple />
              </a>
              <a
                href={process.env.REACT_APP_ANDROID_LINK}
                target="_blank"
              >
                <Android />
              </a>
            </p>
          </div>
          <button className="buy-crypto-signup mt-2" onClick={() => window.open("https://wallet.globiancepay.com", "_blank")}>
            {translateMessage("Sign Up")}
          </button>

          <h6 className="or-separator">{translateMessage("OR")}</h6>

          <div className="buy-crypto-button" onClick={() => setExternal(true)}>
            <h6>{translateMessage("Buy Crypto on an external website")}</h6>
            <p>{translateMessage("by card no registration needed")}</p>
          </div>

        </div>}
        <div className="footer-IPS">
          <div className="curve"></div>
        </div>
      </div>
    </>
  );
}

export default InitializePaybis;
