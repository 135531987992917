import CompletedPNG from "../../assets/images/completed.png";
import { translateMessage } from "../../helper/constant";
import BackBar from "./BackBar";

const Completed = () => {
  return (
    <>
      <BackBar />
      <div className="completed-sec">
        <img src={CompletedPNG} alt="Completed PNG" />
        <h6>{translateMessage("Payment Completed")}</h6>
      </div>
    </>
  );
};

export default Completed;
