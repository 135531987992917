export const DeepEqual = (obj1: any, obj2: any): boolean => {
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 == null ||
    obj2 == null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !DeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const GetCurrencySymbol = (currency: string):string => {
  switch(currency) {
    case "USD": return "$"
    case "EUR": return "€"
    default: return ""
  }
}
