import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import QRCode from "react-qr-code";
import QuotesSections from './QuotesSections';
import BackBar from './BackBar';
import { translateMessage } from '../../helper/constant';
import { initNetbanking } from '../../helper/api';

const QRCodeScreen = () => {
    const modalState = useSelector((state: ReduxState) => state.widgetReducer);

    let data = {
        "checkoutId": modalState.checkoutData.checkoutId,
        "isCryptoPay": true
    }

    return (
        <>
        <BackBar/>
            <div className='qr-screen'>
                <div className='qr-sec wallet'>
                    <QRCode value={JSON.stringify(data)} />
                    <div className='text'>
                        <p>
                            <span className='scan-text'>{translateMessage("Scan with your Globiance App")}</span>&nbsp;<span className='or'>{translateMessage("or")}</span>&nbsp;<span onClick={()=> initNetbanking()} className='pointer login'>{translateMessage("LOGIN")}</span>
                        </p>
                        <p>
                            <span className='info-text'>SAVE on surcharge fees by paying using Globiance APP or netbanking!</span>
                        </p>
                    </div>
                </div>
                <div className='avail-curr'>
                    <h5>{translateMessage("Available Currencies")}</h5>
                    {modalState.checkoutDetails.quotes && modalState.checkoutDetails.quotes.length && <QuotesSections showNetwork={false} quotes={modalState.checkoutDetails.quotes} filter='wallet' />}
                </div>
            </div>
        </>
    )
}

export default QRCodeScreen;