import Homepage from "../Homepage";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import styles from "./container.module.scss";
import ModalComponent from "../widget/ModalComponent";
import { getQueryParameters } from "../../helper/constant";

const Container = (props: any) => {
	const modalState = useSelector((state: ReduxState) => state.widgetReducer);
	const [checkoutId, setCheckoutId] = useState(null);
	const [apiKey, setApiKey] = useState(null);

	useEffect(() => {
		const currentURL = window.location.href;

		const searchParams = getQueryParameters(currentURL);

		setCheckoutId(searchParams.checkoutId);
		setApiKey(searchParams.apiKey);
	}, [window.location.href]);

	return (
		<div className={styles.MainContainer}>
			{modalState.merchantData.showTaxId && <div className={styles.Taxsection}>
				{modalState.checkoutDetails.checkoutId && <div className="d-flex w-100">
					<div className={`${styles["img-area"]} flex-shrink-0`}>
						<img src={modalState.merchantData.logo!} alt='logo' />
					</div>
					<div className='title-area flex-grow-1 ms-md-3'>
						<div className={styles.labelCls}>
							<h5>Tax Id: {" "}<span className="fw-normal">{modalState.merchantData.taxId}</span></h5>
							<h5>Tax Rate: {" "}<span className="fw-normal">{modalState.merchantData.taxRate} %</span></h5>
						</div>
					</div>
				</div>}
			</div>}
			{(checkoutId && apiKey) ? <div id="Cont-Sec" className={styles.CheckoutSec}>
				<ModalComponent checkoutId={checkoutId} apikey={apiKey} />
			</div> : <Homepage />}
		</div>
	);
}

export default Container;
