import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as action from "../../store/actions";
import { translateMessage } from '../../helper/constant';

const PaymentStatus = () => {
    const dispatch = useDispatch();
    const modalState = useSelector((state: ReduxState) => state.widgetReducer);

    return (
        <div className={`nav notification-status-bar`}>
            {modalState.paymentNotification.message.length! > 0 ? <div className={`notification-${modalState.paymentNotification.color}`}>
                <p className='blink_me'>
                    {modalState.paymentNotification.message}
                </p>
            </div> : modalState.checkoutDetails.status === "completed" ? <div className={`notification-green cursor`} onClick={() => dispatch(action.updateWidgetScreenState('PaymentList'))}>
                <p className='completed'>{translateMessage("Payment Completed!")}</p>
                <p className='last'>{translateMessage("More")}{' >'}</p>
            </div> : modalState.checkoutDetails.payments.filter((item) => item.status !== 'confirmed').length ?
                <div className={`notification-yellow cursor`} onClick={() => dispatch(action.updateWidgetScreenState('PaymentList'))}>
                    <p>
                    {`${translateMessage("PAYMENT PROCESSING")} (${modalState.checkoutDetails.payments.filter((item)=> item.status === 'processing').length!})`}
                    {modalState.checkoutDetails.payments.filter((item)=>item.status === 'disputed').length ? ` ${translateMessage("PAYMENT DISPUTED")} (${modalState.checkoutDetails.payments.filter((item)=> item.status === 'disputed').length!})`: ''}
                    {modalState.checkoutDetails.payments.filter((item)=>item.status === 'refunded').length ? ` ${translateMessage("PAYMENT REFUNDED")} (${modalState.checkoutDetails.payments.filter((item)=> item.status === 'refunded').length!})`: ''}
                    {modalState.checkoutDetails.payments.filter((item)=>item.status === 'partially refunded').length ? ` ${translateMessage("PAYMENT PARTIALLY REFUNDED")} (${modalState.checkoutDetails.payments.filter((item)=> item.status === 'partially refunded').length!})`: ''}
                    </p>
                    {modalState.widgetScreen !== "PaymentList" && <p className='last'>{translateMessage("More")}{' >'}</p>}
                </div> : ""}
        </div>
    )
}

export default PaymentStatus