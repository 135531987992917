export const GET_TOKEN_AND_PAIR_PRECISION_DATA_START = 'GET_TOKEN_AND_PAIR_PRECISION_DATA_START';
export const GET_TOKEN_AND_PAIR_PRECISION_DATA_SUCCESS = 'GET_TOKEN_AND_PAIR_PRECISION_DATA_SUCCESS';
export const GET_TOKEN_AND_PAIR_PRECISION_DATA_FAIL = 'GET_TOKEN_AND_PAIR_PRECISION_DATA_FAIL';

export const GET_METADATA_SUCCESS = 'GET_METADATA_SUCCESS';


export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';
export const UPDATE_WIDGET_PARAMS = 'UPDATE_WIDGET_PARAMS';

export const UPDATE_WIDGET_SCREEN_STATE = 'UPDATE_WIDGET_SCREEN_STATE';

export const GET_MERCHANT_STORE = 'GET_MERCHANT_STORE';
export const GET_MERCHANT_STORE_SUCCESS = 'GET_MERCHANT_STORE_SUCCESS';
export const GET_MERCHANT_STORE_FAIL = 'GET_MERCHANT_STORE_FAIL';

export const GET_CHECHOUT_DATA = 'GET_CHECHOUT_DATA';
export const GET_CHECHOUT_DATA_FAIL = 'GET_CHECHOUT_DATA_FAIL';
export const GET_CHECHOUT_DATA_SUCCESS = 'GET_CHECHOUT_DATA_SUCCESS';

export const SELECTED_BLOCKCHAIN_WALLET = 'SELECTED_BLOCKCHAIN_WALLET';

export const GET_BLOCKCHAIN_WALLET_ADDRESS = 'GET_BLOCKCHAIN_WALLET_ADDRESS';
export const GET_BLOCKCHAIN_WALLET_ADDRESS_SUCCESS = 'GET_BLOCKCHAIN_WALLET_ADDRESS_SUCCESS';
export const GET_BLOCKCHAIN_WALLET_ADDRESS_FAIL = 'GET_BLOCKCHAIN_WALLET_ADDRESS_FAIL';

export const GET_CHECKOUT_DETAIL = 'GET_CHECKOUT_DETAIL';
export const GET_CHECKOUT_DETAIL_SUCCESS = 'GET_CHECKOUT_DETAIL_SUCCESS';
export const GET_CHECKOUT_DETAIL_FAIL = 'GET_CHECKOUT_DETAIL_FAIL';

export const UPDATE_PAYMENT_NOTIFICATION = 'UPDATE_PAYMENT_NOTIFICATION';

export const RESET = 'RESET';
export const EXPIRED = 'EXPIRED';