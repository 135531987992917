
import Container from '../components/Container';
import Header from '../components/Headers';
import TopBar from '../components/TopBar';
import ModalComponent from '../components/widget/ModalComponent';
import '../scss/main.scss';

export const App = () => {
    return <>
    <Header/>
    <TopBar/>
    <Container/>
    </>
}