//@ts-ignore
import OrderComponent from './OrderComponent';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../common/ErrorFallback';
import { Dispatch, useEffect } from 'react';
import PaymentStatus from './PaymentStatus';
import { translateMessage } from '../../helper/constant';

const ModalComponent = ({checkoutId, apikey}: any): JSX.Element => {
    const dispatch: Dispatch<any> = useDispatch();
    const modalState = useSelector((state: ReduxState) => state.widgetReducer);

    useEffect(() => {
        if(checkoutId && apikey){
            dispatch(actions.updateWidParamsAndMerchantData({
                apikey: apikey
            }));
    
            dispatch(actions.getTokenAndPairsPrecision());
    
            let dataDet = {
                apiKey: apikey,
                checkoutId: checkoutId
            }
            let Icheck = {
                checkoutId: checkoutId,
                loading: true
            }
    
            dispatch(actions.getCheckoutDetailFirst(dataDet))
            dispatch(actions.checkoutDataSuccess(Icheck))
    
    
            let root = document.documentElement;
            root.setAttribute("data-color", 'pink');
        }
    }, [checkoutId, apikey]);

    type GetStatus = {
        "pending": "blue",
        "completed": "green",
        "cancelled": "yellow",
        "expired": "red",
    }

    const statusClass: GetStatus = {
        pending: 'blue',
        completed: 'green',
        cancelled: 'yellow',
        expired: "red",
    }

    return <div
        id="modal-wid-screen"
        className={`globiance-widget-XF99AS`}
    >

        <div
            className="header"
        >
            <div className="title-cls">
                <div className='headerLogo'>
                </div>
                <p className="mx-4" onClick={() => modalState.checkoutDetails && modalState.checkoutDetails.status && modalState.checkoutDetails.status === 'pending' && !modalState.error.error && !modalState.expired ? dispatch(actions.updateWidgetScreenState('Cancel')) : ()=>{}} >
                    <span aria-hidden="true">&times;</span>
                </p>
            </div>
        </div>
        <PaymentStatus />
        <div className="app-scroll">
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
            >
                <OrderComponent />
            </ErrorBoundary>
        </div>
        <div className={`modal-footer notification-${modalState.expired ? 'red' : statusClass[modalState.checkoutDetails.status as keyof GetStatus]}`}>
            <p className='stat'>{translateMessage("checkout status")}</p>
            {modalState.expired ? <p className='status'>{translateMessage("Expired")}</p> : modalState.checkoutDetails.status ? <p className='status'>{modalState.checkoutDetails.status}</p> : <p className='status'>{translateMessage("LOADING")}</p>}
        </div>
    </div >
}

export default ModalComponent;
