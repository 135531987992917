import React from "react";

const Space = ({
  height,
  width,
}: {
  height: number | string;
  width: number | string;
}) => {
  return <div style={{ height, width }} />;
};

export default Space;
