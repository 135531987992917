import QRcode from "../../assets/images/QRcode";
import { useDispatch } from "react-redux";
import * as action from "../../store/actions/index";
import { useSelector } from "react-redux";
import { translateMessage } from "../../helper/constant";

const PayAndScanSection = ({ selected }: { selected: IQuote | null }) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  function getType(str: string) {
    const exist = modalState.checkoutDetails.quotes.filter(
      (item) =>
        selected?.currencyId === item.currencyId && str === item.currencyType
    );
    if (!exist || !exist.length) return null;
    const merged = exist.reduce((acc, cur) => {
      if (!acc.currencyName) acc = cur;
      else {
        if (cur.network && !acc.network?.split(",").includes(cur.network))
          acc.network += `,${cur.network}`;
      }
      return acc;
    }, {});
    return merged;
  }

  const dispatchBC = (quote: IQuote) => {
    dispatch(action.selectBlockChainWalletAddress(quote));
    dispatch(action.updateWidgetScreenState("BlockchainWallet"));
  };

  return (
    <div className="pay-and-scan-sec">
      <h5>{translateMessage("Scan & Pay")}</h5>
      <div>
        <div
          className={`card-sec ${
            selected && getType("blockchain") ? "cursor" : "disabled"
          }`}
          onClick={() => dispatchBC(getType("blockchain")!)}
        >
          <QRcode />
          <div>
            <h6>{translateMessage("Other Wallet")}</h6>
            <small>{translateMessage("Pay with your preferred wallet")}</small>
          </div>
        </div>

        <div
          className={`card-sec ml5 cursor`}
          onClick={() => dispatch(action.updateWidgetScreenState("QR"))}
        >
          <QRcode />
          <div>
            <h6>{translateMessage("GlobiancePay Wallet")}</h6>
            <small>{translateMessage("Pay with your GlobiancePay Wallet")}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayAndScanSection;
