import React from 'react'

const Copy = () => {
	return (
		<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.13519 3.427H13.8647C14.8042 3.427 15.5729 4.19569 15.5729 5.13519V15.2989C15.5729 16.2384 14.8042 17.0071 13.8647 17.0071H6.13519C5.19569 17.0071 4.427 16.2384 4.427 15.2989V5.13519C4.427 4.19569 5.19569 3.427 6.13519 3.427Z" fill="#F0F0F0" stroke="#A4A5A5" strokeWidth="0.854092" />
			<g clipPath="url(#clip0_146_2000)">
				<path d="M2.13525 0.427002H9.86479C10.8043 0.427002 11.573 1.19569 11.573 2.13519V12.2989C11.573 13.2384 10.8043 14.0071 9.86479 14.0071H2.13525C1.19575 14.0071 0.427063 13.2384 0.427063 12.2989V2.13519C0.427063 1.19569 1.19575 0.427002 2.13525 0.427002Z" fill="#F0F0F0" stroke="#A4A5A5" strokeWidth="0.854092" />
			</g>
			<defs>
				<clipPath id="clip0_146_2000">
					<rect width="12" height="14.4342" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export default Copy